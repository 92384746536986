import * as React from "react";
import { useCallback, useState } from "react";
import DialogHeader from "./DialogHeader";
import DialogFooter from "./DialogFooter";
import QRCode from "react-qr-code";
import GtbTextField from "../Input/GtbTextField";
import useGtbForm from "../../hooks/formHandling/useGtbForm";
import "./totpDialog.css";
import "./dialog.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useModal } from "../modal/ModalProvider";
import { Dialog } from "./useDialog";
import yup, { validateString } from "../../utils/yupExtension";
import useGtbTranslation from "../../i18n/useGtbTranslation";
import DialogContent from "./DialogContent";
import { DialogOptions } from "./DialogOptions";

export type ConfirmTotpType = {
    afterConfirm: () => void;
    onConfirmError: any;
    totp: string;
};

export type ConfirmTotpInputTypes = {
    totp: string;
};

export const totpValidationSchema = yup.object().shape({
    totp: validateString().isRequired(),
});

export default function useActivateTotpDialog() {
    const { showModal: _showDialog, closeModal: closeDialog } = useModal();

    return useCallback(
        (
            username: string,
            secretToken: string,
            onConfirmTotp: ({ afterConfirm, onConfirmError, totp }: ConfirmTotpType) => Promise<any>
        ) => {
            _showDialog(
                <ActivateTotpDialogContent
                    secretToken={secretToken}
                    username={username}
                    closeDialog={closeDialog}
                    onConfirmTotp={onConfirmTotp}
                />
            );
        },
        [closeDialog, _showDialog]
    );
}

function ActivateTotpDialogContent({
    username,
    secretToken,
    closeDialog,
    onConfirmTotp,
}: {
    username: string;
    secretToken: string;
    closeDialog: () => void;
    onConfirmTotp: ({ afterConfirm, onConfirmError, totp }: ConfirmTotpType) => Promise<any>;
}) {
    const translation = useGtbTranslation();
    const [isConfirmLoading, setIsConfirmLoading] = useState(false);

    const { registerWithErrors: register, form } = useGtbForm<ConfirmTotpInputTypes>({
        defaultValues: {
            totp: "",
        },
        resolver: yupResolver(totpValidationSchema),
    });

    const confirmTotp = useCallback(() => {
        form.handleSubmit((body) => {
            setIsConfirmLoading(true);
            onConfirmTotp({
                afterConfirm: closeDialog,
                onConfirmError: form.setError,
                totp: body.totp.replace(/\s/g, ""),
            })
                .then(() => setIsConfirmLoading(false))
                .catch(() => setIsConfirmLoading(false));
        })();
    }, [closeDialog, form, onConfirmTotp]);

    return (
        <Dialog
            dialog={{
                header: (
                    <DialogHeader
                        title={"totp.activate_dialogTitle"}
                        variant="info"
                        onClick={() => {
                            closeDialog();
                        }}
                    />
                ),
                content: (
                    <DialogContent>
                        <p className={"secretTokenInfo"}>{translation("totp.activate_dialogText")}</p>
                        <div className="secretTokenQrContainer">
                            <QRCode
                                className="secretTokenQr"
                                size={256}
                                value={`otpauth://totp/GTB%20(${username})?secret=${secretToken}`}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <p className={"secretToken"}>{secretToken}</p>
                        <div className="confirmOtpField">
                            <GtbTextField label={"totp.activate_dialogInput"} autoFocus {...register("totp")} />
                        </div>
                    </DialogContent>
                ),
                footer: (
                    <DialogFooter>
                        <DialogOptions
                            options={[
                                {
                                    onClick: closeDialog,
                                    label: "components.dialog.cancel_button",
                                    disabled: isConfirmLoading,
                                },
                                {
                                    onClick: confirmTotp,
                                    label: "totp.confirmConfiguration_button",
                                    disabled: isConfirmLoading,
                                },
                            ]}
                        />
                    </DialogFooter>
                ),
            }}
        />
    );
}
