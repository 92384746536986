import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Nullable } from "../../utils/typeUtils";
import { AccreditationBodyDetails } from "../accreditationBodyTypes";
import { accreditationBodyValidationSchema } from "./accreditationBodyValidationSchema";
import { AccreditationBodyDetailViewProps, AccreditationBodyReadOnlyProps } from "./AccreditationBodyDetailView";
import {
    organisationDefaultInitialData,
    useOrganisationDefaultReadOnlyProps,
} from "../../organisation/details/organisationDetailViewProps";
import { useMemo } from "react";

const initialData: Nullable<AccreditationBodyDetails> = {
    legalName: null,
    ...organisationDefaultInitialData,
};

export default function useAccreditationBodyCreate(): AccreditationBodyDetailViewProps {
    const { detailViewProps, form, title } = useDetailsCreate<AccreditationBodyDetails>({
        title: "accreditationBody.detail.newRecord_title",
        frontendRoute: "accreditationBody",
        initialData,
        resolver: yupResolver(accreditationBodyValidationSchema),
        saveUrl: "/certificate-service/accreditation-bodies",
    });

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(false);

    const readOnlyProps = useMemo<AccreditationBodyReadOnlyProps>(
        () => ({
            ...defaultReadOnlyProps,
        }),
        [defaultReadOnlyProps]
    );

    return {
        title,
        detailViewProps,
        form,
        readOnlyProps,
        createRoute: "accreditationBody",
    };
}
