import { OrganisationDetails, OrganisationReference, OrganisationSummary } from "../organisation/organisationTypes";
import { I18nKey } from "../i18n/useGtbTranslation";

export interface SupplyChainOperatorSummary extends OrganisationSummary {
    stateProvinceName: string;
    legalName: string;
    publicContactPersonName: string;
    publicContactPersonEmail: string;
    organisationalContactPersonName: string;
    organisationalContactPersonEmail: string;
    brandNames: string;
    membership: membershipTypeEnum;
    fixedRegistrationId: string;
    legalNameInEnglish?: string;
}

export interface SupplyChainOperatorDetails extends OrganisationDetails {
    legalName: string;
    isReducedAccess: boolean;
    fixedRegistrationId?: string;
    mainFacility?: string;
    brandNames?: string;
    membership?: membershipTypeEnum;
    publicContactPersonName?: string;
    publicContactPersonEmail?: string;
    organisationalContactPersonName?: string;
    organisationalContactPersonEmail?: string;
    cbInternalId?: string;
    legalNameInEnglish?: string;
    relationType?: supplyChainOperatorRelationTypeEnum;
    standard?: string;
    certificationBody?: string | null;
    principalSupplyChainOperator?: string | null;
    sellerSupplyChainOperator?: string | null;
}

export interface SupplyChainOperatorReference
    extends OrganisationReference,
        Pick<SupplyChainOperatorSummary, "systemId" | "legalNameInEnglish" | "organisationalEmail"> {}

export type membershipTypeEnum = "NONE" | "IVN" | "SA" | "JOCA" | "OTA";

export type supplyChainOperatorRelationTypeEnum =
    | "CERTIFICATION_RELATION"
    | "SUBCONTRACTING_RELATION"
    | "BUYING_RELATION";

export const supplyChainOperatorRelationCbType: Record<supplyChainOperatorRelationTypeEnum, I18nKey> = {
    CERTIFICATION_RELATION: "supplyChainOperator.detail.newClient_radiobutton",
    SUBCONTRACTING_RELATION: "supplyChainOperator.detail.newSubcontractor_radiobutton",
    BUYING_RELATION: "supplyChainOperator.detail.newBuyer_radiobutton",
};

export const supplyChainOperatorRelationSbType: Record<supplyChainOperatorRelationTypeEnum, I18nKey> = {
    CERTIFICATION_RELATION: "supplyChainOperator.detail.certificationRelation_radiobutton",
    SUBCONTRACTING_RELATION: "supplyChainOperator.detail.subcontractingRelation_radiobutton",
    BUYING_RELATION: "supplyChainOperator.detail.buyingRelation_radiobutton",
};

export const supplyChainOperatorRelationScoType: Partial<Record<supplyChainOperatorRelationTypeEnum, I18nKey>> = {
    SUBCONTRACTING_RELATION: "supplyChainOperator.detail.newSubcontractor_radiobutton",
    BUYING_RELATION: "supplyChainOperator.detail.newBuyer_radiobutton",
};
