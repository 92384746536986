import { TypedEnumEntityType } from "../components/Input/pickList/typedEnumPicklist/enumEntityType";
import { I18nKey } from "../i18n/useGtbTranslation";

export const buildEnumOptionsFromObject: (obj: { [key: string]: string }) => {
    id: string;
    label: string;
}[] = (obj) => {
    return Object.entries(obj).map((e) => {
        return { id: e[0], label: e[1] };
    });
};

export const mapTypedEnumEntityToTranslation = <T>(source: TypedEnumEntityType<T>[], option: T) =>
    source.filter((source) => source.id === option).map((source) => source.name)[0] as I18nKey;
