import EnumEntityRadiobuttonGroup, { TypedRadioButtonGroupProps } from "../EnumEntityRadiobuttonGroup";
import React from "react";

interface TypeOfTcRadioButtonGroupFlags {
    forOrigin?: string;
}

interface TypeOfTcRadioButtonGroupProps extends TypedRadioButtonGroupProps {
    flags?: TypeOfTcRadioButtonGroupFlags;
}

const TypeOfTcRadioButtonGroup = React.forwardRef<HTMLInputElement, TypeOfTcRadioButtonGroupProps>(
    ({ ...rest }, ref) => {
        return (
            <EnumEntityRadiobuttonGroup
                referenceUrl="/certificate-service/references/type-of-om-tc"
                ref={ref}
                {...rest}
            />
        );
    }
);

export default TypeOfTcRadioButtonGroup;
