import { buildSchema, validateString } from "../../utils/yupExtension";
import { organisationValidationSchema } from "../../organisation/details/organisationValidationSchema";
import { SupplyChainOperatorDetails } from "../supplyChainOperatorTypes";

export const buildSupplyChainOperatorValidationSchema = (requiresLegalNameInEnglish: boolean) => {
    return buildSchema<SupplyChainOperatorDetails>({
        ...organisationValidationSchema,
        legalName: validateString().isRequired().hasMaxLength(150),
        fixedRegistrationId: validateString().hasMaxLength(30),
        brandNames: validateString().hasMaxLength(500),
        publicContactPersonName: validateString().hasMaxLength(80),
        publicContactPersonEmail: validateString().hasMaxLength(100).isEmail(),
        organisationalContactPersonName: validateString().hasMaxLength(80),
        organisationalContactPersonEmail: validateString().hasMaxLength(100).isEmail(),
        legalNameInEnglish: requiresLegalNameInEnglish
            ? validateString().hasMaxLength(150).isRequired()
            : validateString(),
        cbInternalId: validateString().hasMaxLength(40),
    });
};
