import yup, { buildSchema, validateNumber, validateString } from "../../utils/yupExtension";
import { FacilityDetails } from "../facilityTypes";
import { organisationValidationSchema } from "../../organisation/details/organisationValidationSchema";

export const buildFacilityValidationSchema = (requiresLegalNameInEnglish: boolean) => {
    return buildSchema<FacilityDetails>({
        ...organisationValidationSchema,
        independentLegalEntity: yup.boolean(),
        legalName: validateString()
            .hasMaxLength(150)
            .when("independentLegalEntity", {
                is: true,
                then: (schema) => schema.isRequired(),
            }),
        fixedRegistrationId: validateString().hasMaxLength(30),
        numberOfEmployees: validateNumber().isRequired().hasMinValue(1),
        legalNameInEnglish: validateString().when("independentLegalEntity", {
            is: (independentLegalEntity: boolean | undefined) => independentLegalEntity && requiresLegalNameInEnglish,
            then: (schema) => schema.isRequired().hasMaxLength(150),
        }),
        cbInternalId: validateString().hasMaxLength(40),
    });
};
