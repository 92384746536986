import CountryAreaBasicData from "./CountryAreaBasicData";
import CountryAreaAdditionalData from "./CountryAreaAdditionalData";
import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import { CountryAreaDetails } from "../countryAreaTypes";
import "../../components/spinner.css";
import { TypedDetailViewProps } from "../../components/detailView/DetailView";
import { useHelpContext } from "../../help/context-sensitivity/useHelpContext";
import EntityDetailView from "../../components/entity/EntityDetailView";
import { buildLanguageVersionTab } from "../../i18n/LanguageVersionTab";
import "./countryAreaLanguageVersion.css";
import Tabs, { TabKey } from "../../components/tabs/Tabs";

export default function CountryAreaDetailView({
    isReadOnly,
    subListVisible,
    form,
    ...rest
}: CountryAreaDetailViewProps) {
    useHelpContext("country-area-details");

    return (
        <EntityDetailView {...rest} form={form}>
            <Tabs<CountryAreaDetails, CountryAreaTabs>
                errors={form.form.formState.errors}
                tabs={[
                    {
                        heading: "countryArea.detail.basicData_tab",
                        element: (
                            <CountryAreaBasicData form={form} readOnly={isReadOnly} subListVisible={subListVisible} />
                        ),
                    },
                    {
                        heading: "countryArea.detail.additionalData_tab",
                        element: <CountryAreaAdditionalData form={form} readOnly={isReadOnly} />,
                    },
                    buildLanguageVersionTab({
                        form,
                        fields: ["name"],
                        baseName: "countryArea",
                        readOnly: isReadOnly,
                    }),
                ]}
                errorToTab={{
                    id: "internal",
                    endDate: "countryArea.detail.basicData_tab",
                    addressPattern: "countryArea.detail.additionalData_tab",
                    callingCode: "countryArea.detail.basicData_tab",
                    isoCode: "countryArea.detail.basicData_tab",
                    subdivisionsMandatory: "countryArea.detail.basicData_tab",
                    name: "translation.languageVersion_tab",
                    registrationIdType: "countryArea.detail.basicData_tab",
                    registrationIdTypeOmp: "countryArea.detail.basicData_tab",
                    welcomeMessage: "countryArea.detail.additionalData_tab",
                    startDate: "countryArea.detail.basicData_tab",
                    nameTranscriptions: "countryArea.detail.basicData_tab",
                    noFarmTcPermitted: "countryArea.detail.basicData_tab",
                }}
            />
        </EntityDetailView>
    );
}

type CountryAreaTabs = TabKey<
    "countryArea.detail.basicData_tab" | "countryArea.detail.additionalData_tab" | "translation.languageVersion_tab"
>;

export interface CountryAreaDetailViewProps extends TypedDetailViewProps<CountryAreaDetails> {
    subListVisible?: boolean;
    isReadOnly: boolean;
}

export interface CountryAreaDetailViewTabProps {
    form: UseGtbFormReturn<CountryAreaDetails>;
    readOnly: boolean;
    subListVisible?: boolean;
}
