import { useCallback, useEffect } from "react";
import { useQuery } from "../../../hooks/useAxios";
import ControlledPickList, { ControlledPickListProps } from "./ControlledPickList";
import { FieldValues } from "react-hook-form/dist/types";
import useFlaggedQueryUrl from "../../../hooks/useFlaggedQueryUrl";

export interface DataPickListProps<FormItemType extends FieldValues, ItemType>
    extends Omit<ControlledPickListProps<FormItemType, ItemType>, "data"> {
    queryUrl: string;
    additionalDataUrl?: string;
    flags?: {};
}

function DataPickList<FormItemType extends FieldValues, ItemType>({
    queryUrl,
    additionalDataUrl = queryUrl,
    disabled,
    flags,
    ...rest
}: DataPickListProps<FormItemType, ItemType>) {
    const {
        data: initialData,
        isLoading: initialDataIsLoading,
        runQuery: runInitialDataQuery,
    } = useQuery<ItemType[]>({ url: "", enabled: false });

    const queryUrlWithFlags = useFlaggedQueryUrl(queryUrl, flags);

    const { isLoading: singleDataIsLoading, runQuery: refetchUnknownItem } = useQuery<ItemType>({
        url: "",
        enabled: false,
    });

    const loadAdditionalItem = useCallback(
        (option: ItemType[keyof ItemType]) => refetchUnknownItem({ url: additionalDataUrl + "/" + option }),
        [refetchUnknownItem, additionalDataUrl]
    );

    useEffect(() => {
        if (!disabled && queryUrlWithFlags) {
            runInitialDataQuery({ url: queryUrlWithFlags }).then(/**/);
        }
    }, [disabled, queryUrlWithFlags, runInitialDataQuery]);

    return (
        <ControlledPickList
            data={initialData}
            isLoading={initialDataIsLoading || singleDataIsLoading || !queryUrl}
            loadAdditionalItem={loadAdditionalItem}
            disabled={disabled}
            {...rest}
        />
    );
}

export default DataPickList;
