import { useMemo } from "react";
import { backendUrlType } from "./useAxios";

export default function useFlaggedQueryUrl(queryUrl: backendUrlType, flags?: {}) {
    return useMemo(() => {
        if (flags && queryUrl) {
            const validFlags = Object.entries(flags)
                .filter(([_flag, val]) => val)
                .flatMap(([flag, val]) => (Array.isArray(val) ? val.map((v) => [flag, v]) : [[flag, val]]))
                .map((val) => `${val[0]}=${val[1]}`)
                .join("&");

            return `${queryUrl}?${validFlags}`;
        }

        return queryUrl;
    }, [flags, queryUrl]);
}
