import { validateDate, validateNumber, validateString, ValidationBaseSchema } from "../../utils/yupExtension";
import { OrganisationDetails } from "../organisationTypes";

export const organisationValidationSchema: ValidationBaseSchema<OrganisationDetails> = {
    businessName: validateString().isRequired().hasMaxLength(150),
    website: validateString().hasMaxLength(250).isUrl(),
    organisationalEmail: validateString().isRequired().hasMaxLength(100).isEmail(),
    organisationalPhoneNumber: validateString().hasMaxLength(20),
    startDate: validateDate(),
    endDate: validateDate(),
    town: validateString().isRequired().hasMaxLength(40),
    postCode: validateString().hasMaxLength(10),
    countryArea: validateString().isRequired(),
    stateProvince: validateString().isRequired(),
    addressDetails1: validateString().isRequired().hasMaxLength(100),
    addressDetails2: validateString().hasMaxLength(100),
    addressDetails3: validateString().hasMaxLength(100),
    latitude: validateNumber().hasMinValue(-90).hasMaxValue(90),
    longitude: validateNumber().hasMinValue(-180).hasMaxValue(180),
};
