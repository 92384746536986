import { useMemo } from "react";
import { buildBooleanColumn, buildDateColumn, buildTextColumn } from "../components/grid/ColumnProvider";
import { CountryAreaDetails, CountryAreaSummary } from "./countryAreaTypes";
import useIsAuthorised from "../auth/useIsAuthorised";
import { SortOption } from "../components/grid/component/useQueryBuilder";
import { useDeleteAction, useShowDetailsAction } from "../utils/defaultActions";
import useListView from "../hooks/useListView";
import { GridColumn } from "../components/grid/component/Grid";
import { EntityListViewWithoutInfinityScrollGridOptions } from "../components/entity/EntityListViewExcludingInfinityScroll";
import useResolvedRoute, { getCreateUrl } from "../components/routing/useResolvedRoute";
import useGridRowContextMenuActions from "../components/grid/component/cell/useGridRowContextMenuActions";

export const defaultCountryAreaSortOption: SortOption<CountryAreaSummary | CountryAreaDetails> = {
    field: "name",
    direction: "ASC",
};

const columns: GridColumn<CountryAreaSummary>[] = [
    buildTextColumn({ key: "name", title: "countryArea.list.name_column", minWidth: 123 }),
    buildTextColumn({ key: "isoCode", title: "countryArea.list.isoCode_column", minWidth: 126 }),
    buildTextColumn({ key: "callingCode", title: "countryArea.list.callingCode_column", minWidth: 147 }),
    buildBooleanColumn({
        key: "subdivisionsMandatory",
        title: "countryArea.list.subdivisionsMandatory_column",
        minWidth: 253,
    }),
    buildTextColumn({
        key: "registrationIdType",
        title: "countryArea.list.registrationIdType_column",
        minWidth: 225,
    }),
    buildTextColumn({
        key: "addressPattern",
        title: "countryArea.list.addressPattern_column",
        minWidth: 179,
        visible: false,
    }),
    buildDateColumn({ key: "endDate", title: "countryArea.list.endDate_column", minWidth: 124, visible: false }),
    buildDateColumn({
        key: "startDate",
        title: "countryArea.list.startDate_column",
        minWidth: 130,
        visible: false,
    }),
    buildTextColumn({
        key: "registrationIdTypeOmp",
        title: "countryArea.list.registrationIdTypeOmp_column",
        minWidth: 301,
        visible: false,
    }),
    buildTextColumn({
        key: "welcomeMessage",
        title: "countryArea.list.welcomeMessage_column",
        minWidth: 199,
        visible: false,
    }),
    buildBooleanColumn({
        key: "nameTranscriptions",
        title: "countryArea.list.name_transcriptions_column",
        minWidth: 140,
        visible: false,
    }),
    buildBooleanColumn({
        key: "noFarmTcPermitted",
        title: "countryArea.list.no_farm_tc_permitted_column",
        minWidth: 225,
        visible: false,
    }),
];

function useCountryAreaListView() {
    const { hasAccessRight } = useIsAuthorised();
    const { getResolvedEditUrl } = useResolvedRoute();

    const {
        queryBuilder: { forceReload, columnOptions, query, queryCommands },
    } = useListView<CountryAreaSummary>({
        navigationContextLabel: "countryArea.breadcrumb",
        baseQuery: "/certificate-service/countries",
        defaultSortOption: defaultCountryAreaSortOption,
    });

    const showDetailsAction = useShowDetailsAction(
        (item: CountryAreaSummary) => getResolvedEditUrl("countryArea", item.id),
        query.parameter
    );

    const deleteAction = useDeleteAction(
        (item: CountryAreaSummary) => `/certificate-service/countries/${item.id}`,
        () => forceReload(),
        "deleteMasterData"
    );

    const actions = useGridRowContextMenuActions(showDetailsAction, deleteAction);

    return useMemo<EntityListViewWithoutInfinityScrollGridOptions<CountryAreaSummary>>(() => {
        return {
            query,
            columns,
            uniqueDataKey: "id" as keyof CountryAreaSummary,
            onItemDoubleClick: showDetailsAction,
            createUrl: hasAccessRight("createMasterData") ? getCreateUrl("countryArea") : undefined,
            tableName: "countryArea",
            itemActions: actions,
            columnOptions,
            queryCommands,
            bulkDeleteAccessRight: "deleteMasterData",
        };
    }, [query, showDetailsAction, hasAccessRight, actions, columnOptions, queryCommands]);
}

export default useCountryAreaListView;
