import useDetailsCreate from "../../hooks/details/useDetailsCreate";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    SupplyChainOperatorDetails,
    supplyChainOperatorRelationCbType,
    supplyChainOperatorRelationSbType,
    supplyChainOperatorRelationScoType,
    supplyChainOperatorRelationTypeEnum,
} from "../supplyChainOperatorTypes";
import { buildSupplyChainOperatorValidationSchema } from "./supplyChainOperatorValidationSchema";
import { SupplyChainOperatorDetailViewProps, SupplyChainOperatorReadOnlyProps } from "./SupplyChainOperatorDetailView";
import useResolvedRoute from "../../components/routing/useResolvedRoute";
import {
    organisationDefaultInitialData,
    useOrganisationDefaultReadOnlyProps,
} from "../../organisation/details/organisationDetailViewProps";
import useTypeOfRegistrationId from "../../countryArea/useTypeOfRegistrationId";
import useResolveDuplicates from "../../hooks/details/useResolveDuplicates";
import { externalIdComponentTypeEnum } from "../../externalId/externalIdTypes";
import { useEffect, useMemo, useState } from "react";
import useAuth from "../../auth/useAuth";
import useAcceptSupplyChainOperatorDuplicate from "./useAcceptSupplyChainOperatorDuplicate";
import { Nullable } from "../../utils/typeUtils";
import { NewSupplyChainOperatorRelationProps } from "./SupplyChainOperatorNewRelation";
import { validateString } from "../../utils/yupExtension";
import { organisationTypeEnum } from "../../authorisationRole/authorisationRoleTypes";
import useRequiresLegalNameInEnglish from "../../organisation/useRequiresLegalNameInEnglish";

export default function useSupplyChainOperatorCreate(): SupplyChainOperatorDetailViewProps {
    const { getResolvedId } = useResolvedRoute();
    const supplyChainOperatorId = getResolvedId("supplyChainOperator");
    const {
        currentUser: { authorisationRole, organisation },
    } = useAuth();
    const [requiresLegalNameInEnglish, setRequiresLegalNameInEnglish] = useState(false);

    const initialData = useMemo<Nullable<SupplyChainOperatorDetails>>(() => {
        let initialRelationType: supplyChainOperatorRelationTypeEnum | null;
        if (authorisationRole?.type === "SUPPLY_CHAIN_OPERATOR") {
            initialRelationType = "SUBCONTRACTING_RELATION";
        } else if (authorisationRole?.type === "CERTIFICATION_BODY" || authorisationRole?.type === "STANDARD_BODY") {
            initialRelationType = "CERTIFICATION_RELATION";
        } else {
            initialRelationType = null;
        }

        return {
            ...organisationDefaultInitialData,
            isReducedAccess: false,
            legalName: null,
            brandNames: null,
            fixedRegistrationId: null,
            membership: "NONE",
            mainFacility: null,
            organisationalContactPersonEmail: null,
            organisationalContactPersonName: null,
            publicContactPersonEmail: null,
            publicContactPersonName: null,
            cbInternalId: null,
            relationType: initialRelationType,
            standard: null,
            certificationBody: authorisationRole?.type === "CERTIFICATION_BODY" ? organisation : null,
            principalSupplyChainOperator: authorisationRole?.type === "SUPPLY_CHAIN_OPERATOR" ? organisation : null,
            sellerSupplyChainOperator: authorisationRole?.type === "SUPPLY_CHAIN_OPERATOR" ? organisation : null,
            legalNameInEnglish: null,
        };
    }, [authorisationRole?.type, organisation]);

    const acceptDuplicate = useAcceptSupplyChainOperatorDuplicate();
    const duplicateResolver = useResolveDuplicates<SupplyChainOperatorDetails>(acceptDuplicate);

    const isRelationTabVisible = useMemo(
        () =>
            authorisationRole?.type === "CERTIFICATION_BODY" ||
            authorisationRole?.type === "STANDARD_BODY" ||
            authorisationRole?.type === "SUPPLY_CHAIN_OPERATOR",
        [authorisationRole?.type]
    );

    const isRelationCertificationBodyAccessible = useMemo(
        () => authorisationRole?.type === "STANDARD_BODY",
        [authorisationRole?.type]
    );

    const isRelationSupplyChainOperatorAccessible = useMemo(
        () => authorisationRole?.type === "CERTIFICATION_BODY" || authorisationRole?.type === "STANDARD_BODY",
        [authorisationRole?.type]
    );

    const { detailViewProps, form, title } = useDetailsCreate<SupplyChainOperatorDetails>({
        title: "supplyChainOperator.detail.newRecord_title",
        frontendRoute: "supplyChainOperator",
        resolveErrors: duplicateResolver,
        initialData,
        resolver: yupResolver(
            buildSupplyChainOperatorValidationSchema(requiresLegalNameInEnglish).shape({
                standard: isRelationTabVisible ? validateString().isRequired() : validateString(),
                certificationBody: validateString().when("relationType", {
                    is: (relationType: supplyChainOperatorRelationTypeEnum) =>
                        isRelationCertificationBodyAccessible && relationType === "CERTIFICATION_RELATION",
                    then: validateString().isRequired(),
                }),
                principalSupplyChainOperator: validateString().when("relationType", {
                    is: (relationType: supplyChainOperatorRelationTypeEnum) =>
                        isRelationSupplyChainOperatorAccessible && relationType === "SUBCONTRACTING_RELATION",
                    then: validateString().isRequired(),
                }),
                sellerSupplyChainOperator: validateString().when("relationType", {
                    is: (relationType: supplyChainOperatorRelationTypeEnum) =>
                        isRelationSupplyChainOperatorAccessible && relationType === "BUYING_RELATION",
                    then: validateString().isRequired(),
                }),
            })
        ),
        saveUrl: "/certificate-service/supply-chain-operators",
    });

    const { typeOfRegistrationId, isFixedRegistrationIdReadOnly } = useTypeOfRegistrationId(form);

    const defaultReadOnlyProps = useOrganisationDefaultReadOnlyProps(false);

    const readOnlyProps = useMemo<SupplyChainOperatorReadOnlyProps>(
        () => ({
            ...defaultReadOnlyProps,
            isFixedRegistrationIdReadOnly: isFixedRegistrationIdReadOnly,
            isMainFacilityReadOnly: false,
            isBrandNamesReadOnly: false,
            isMembershipReadOnly: false,
            isPublicContactPersonNameReadOnly: false,
            isPublicContactPersonEmailReadOnly: false,
            isOrganisationalContactPersonNameReadOnly: false,
            isOrganisationalContactPersonEmailReadOnly: false,
            isCbInternalIdReadOnly: false,
            isLegalNameInEnglishReadOnly: false,
            isSubEntityReadOnly: true,
        }),
        [defaultReadOnlyProps, isFixedRegistrationIdReadOnly]
    );

    const externalIdComponentType = useMemo<externalIdComponentTypeEnum>(() => {
        if (authorisationRole.type === "CERTIFICATION_BODY") {
            return "FIELD";
        } else {
            return "NONE";
        }
    }, [authorisationRole.type]);

    const currentRelationType = form.form.watch("relationType");

    const newSupplyChainOperatorRelationProps = useMemo<NewSupplyChainOperatorRelationProps | undefined>(() => {
        if (isRelationTabVisible) {
            let newRelationType;
            if (authorisationRole?.type === "STANDARD_BODY") {
                newRelationType = supplyChainOperatorRelationSbType;
            } else if (authorisationRole?.type === "CERTIFICATION_BODY") {
                newRelationType = supplyChainOperatorRelationCbType;
            } else {
                newRelationType = supplyChainOperatorRelationScoType;
            }

            return {
                newRelationType,
                isCertificationBodyVisible:
                    isRelationCertificationBodyAccessible && currentRelationType === "CERTIFICATION_RELATION",
                isPrincipalVisible:
                    isRelationSupplyChainOperatorAccessible && currentRelationType === "SUBCONTRACTING_RELATION",
                isSellerVisible: isRelationSupplyChainOperatorAccessible && currentRelationType === "BUYING_RELATION",
            };
        }
    }, [
        authorisationRole?.type,
        isRelationCertificationBodyAccessible,
        isRelationSupplyChainOperatorAccessible,
        isRelationTabVisible,
        currentRelationType,
    ]);

    useEffect(() => {
        if (isRelationSupplyChainOperatorAccessible && !newSupplyChainOperatorRelationProps?.isPrincipalVisible) {
            form.form.setValue("principalSupplyChainOperator", null);
        }
        if (isRelationSupplyChainOperatorAccessible && !newSupplyChainOperatorRelationProps?.isSellerVisible) {
            form.form.setValue("sellerSupplyChainOperator", null);
        }
        if (isRelationCertificationBodyAccessible && !newSupplyChainOperatorRelationProps?.isCertificationBodyVisible) {
            form.form.setValue("certificationBody", null);
        }
    }, [
        form.form,
        isRelationCertificationBodyAccessible,
        isRelationSupplyChainOperatorAccessible,
        newSupplyChainOperatorRelationProps?.isCertificationBodyVisible,
        newSupplyChainOperatorRelationProps?.isPrincipalVisible,
        newSupplyChainOperatorRelationProps?.isSellerVisible,
    ]);

    function createStandardPickListFlags(orgType: organisationTypeEnum | undefined) {
        if (orgType === "CERTIFICATION_BODY") {
            return {
                organisation_type: "CERTIFICATION_BODY" as const,
            };
        } else if (orgType === "STANDARD_BODY") {
            return {
                organisation_type: "STANDARD_BODY" as const,
            };
        }
        return {};
    }

    useRequiresLegalNameInEnglish(form, setRequiresLegalNameInEnglish);

    return {
        title,
        detailViewProps,
        form,
        readOnlyProps,
        createRoute: "supplyChainOperator",
        typeOfRegistrationId,
        supplyChainOperatorId,
        externalIdComponentType,
        reducedAccess: false,
        requiresLegalNameInEnglish: requiresLegalNameInEnglish,
        newSupplyChainOperatorRelationProps: newSupplyChainOperatorRelationProps,
        standardPickListFlags: createStandardPickListFlags(authorisationRole?.type),
        certificationBodyPickListFlags: { sbfilter: authorisationRole?.type === "STANDARD_BODY" },
        supplyChainOperatorPickListFlags: { isClientOfCurrentUser: authorisationRole?.type === "CERTIFICATION_BODY" },
    };
}
