import { buildSchema, validateNumber, validateString } from "../../utils/yupExtension";
import { organisationValidationSchema } from "../../organisation/details/organisationValidationSchema";
import { OrganicMaterialProducerDetails } from "../organicMaterialProducerTypes";

export const buildOrganicMaterialProducerValidationSchema = (requiresLegalNameInEnglish: boolean) => {
    return buildSchema<OrganicMaterialProducerDetails>({
        ...organisationValidationSchema,
        legalName: validateString().isRequired().hasMaxLength(150),
        numberOfFarmers: validateNumber().hasMinValue(0),
        fixedRegistrationId: validateString().hasMaxLength(30),
        legalNameInEnglish: requiresLegalNameInEnglish
            ? validateString().hasMaxLength(150).isRequired()
            : validateString(),
        cbInternalId: validateString().hasMaxLength(40),
    });
};
