import { UseGtbFormReturn } from "../../hooks/formHandling/useGtbForm";
import "../../components/spinner.css";
import { I18nKey } from "../../i18n/useGtbTranslation";
import { OrganisationDetails } from "../organisationTypes";
import { Nullable } from "../../utils/typeUtils";
import useAuth from "../../auth/useAuth";
import { ReactNode, useMemo } from "react";

export interface OrganisationDetailViewTabProps<OrganisationType extends OrganisationDetails> {
    form: UseGtbFormReturn<OrganisationType>;
    readOnlyProps: OrganisationReadOnlyProps;
    organisationI18nKey: I18nKey;
    children?: ReactNode;
}

export interface OrganisationReadOnlyProps {
    isLegalNameReadOnly: boolean;
    isBusinessNameReadOnly: boolean;
    isWebsiteReadOnly: boolean;
    isOrganisationalEmailReadOnly: boolean;
    isOrganisationalPhoneNumberReadOnly: boolean;
    isOrganisationStatusReadOnly: boolean;
    isTownReadOnly: boolean;
    isPostCodeReadOnly: boolean;
    isCountryAreaReadOnly: boolean;
    isStateProvinceReadOnly: boolean;
    isAddressDetails1ReadOnly: boolean;
    isAddressDetails2ReadOnly: boolean;
    isAddressDetails3ReadOnly: boolean;
    isLatitudeReadOnly: boolean;
    isLongitudeReadOnly: boolean;
    isStartDateReadOnly: boolean;
    isEndDateReadOnly: boolean;
}

export function useOrganisationDefaultReadOnlyProps(readOnly: boolean): OrganisationReadOnlyProps {
    const {
        currentUser: { authorisationRole },
    } = useAuth();

    return useMemo(() => {
        const isDatesReadOnly = !(
            authorisationRole.level === 1 ||
            (authorisationRole.level === 2 && authorisationRole.type === "STANDARD_BODY")
        );

        return {
            isLegalNameReadOnly: readOnly,
            isAddressDetails1ReadOnly: readOnly,
            isAddressDetails2ReadOnly: readOnly,
            isAddressDetails3ReadOnly: readOnly,
            isLatitudeReadOnly: readOnly,
            isLongitudeReadOnly: readOnly,
            isBusinessNameReadOnly: readOnly,
            isCountryAreaReadOnly: readOnly,
            isOrganisationStatusReadOnly: readOnly,
            isOrganisationalEmailReadOnly: readOnly,
            isOrganisationalPhoneNumberReadOnly: readOnly,
            isPostCodeReadOnly: readOnly,
            isStateProvinceReadOnly: readOnly,
            isTownReadOnly: readOnly,
            isWebsiteReadOnly: readOnly,
            isStartDateReadOnly: readOnly || isDatesReadOnly,
            isEndDateReadOnly: readOnly || isDatesReadOnly,
        };
    }, [authorisationRole.level, authorisationRole.type, readOnly]);
}

export const organisationDefaultInitialData: Nullable<OrganisationDetails> = {
    addressDetails1: null,
    addressDetails2: null,
    addressDetails3: null,
    latitude: null,
    longitude: null,
    businessName: null,
    countryArea: null,
    endDate: null,
    id: null,
    organisationalEmail: null,
    organisationalPhoneNumber: null,
    postCode: null,
    startDate: new Date(),
    stateProvince: null,
    organisationStatus: null,
    systemId: null,
    town: null,
    website: null,
};
